import React from 'react';
import styled from '@emotion/styled';
import { theme, SectionTitle, SectionSubtitle } from '@styles';
import { WixHowToPublishSectionData } from './types';
import Step1Placeholder from '@assets/images/dashboard-carousel/step1.png';
import Step2Placeholder from '@assets/images/dashboard-carousel/step2.png';
import Step3Placeholder from '@assets/images/dashboard-carousel/step3.png';
import StepsCarouselWix from '@components/StepsCarouselWix';

const { breakpoints } = theme;

const placeholdersMapper = [Step1Placeholder, Step2Placeholder, Step3Placeholder];

const HowToPublish: React.FC<WixHowToPublishSectionData> = (props) => {
  const { how_to_publish_title, how_to_publish_subtitle, how_to_publish_steps } = props;

  return (
    <Wrapper>
      <SectionTitle>{how_to_publish_title}</SectionTitle>
      <SectionSubtitle>{how_to_publish_subtitle}</SectionSubtitle>
      <StepsCarouselWix steps={how_to_publish_steps} placeholdersMapper={placeholdersMapper} />
    </Wrapper>
  );
};

export default HowToPublish;

const Wrapper = styled.div`
  margin-top: 34px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-top: 44px;
  }
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 54px;
  }
`;
