import React from 'react';
import styled from '@emotion/styled';
import { theme } from '../styles';
const { colors, breakpoints } = theme;
import { space, SpaceProps, layout, LayoutProps, compose } from 'styled-system';

type Props = {
  label?: string;
};

const Separator: React.FC<Props & LayoutProps & SpaceProps> = (props) => {
  const { label } = props;
  return (
    <Container label={label} {...props}>
      {label && <Label>{label}</Label>}
    </Container>
  );
};

export default Separator;

const Label = styled.div`
  background-color: ${colors.mystic};
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 4px solid #fff;
  font-size: 12px;
  line-height: 15.6px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
`;

const Container = styled.div<{ label?: string }>`
  user-select: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 1px;
  background-color: ${({ label }) => (label ? colors.mystic : colors.white)};
  margin: 70px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 100px 0px;
    ${compose(space, layout)}
  }
  ${compose(space, layout)}
`;
