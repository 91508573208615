import React from 'react';
import styled from '@emotion/styled';
import { HeaderTitle, HeaderSubtitle, theme, SectionWrapper as SectionWrapperStyles } from '@styles';
import { WixHeaderSectionData } from './types';
import Button from '@components/Button';
import { useWindowSize } from 'react-use';

const { breakpoints } = theme;

const HeaderSection: React.FC<WixHeaderSectionData> = (props) => {
  const { header_title, header_subtitle } = props;

  const { width } = useWindowSize();
  const breakpointSmallLaptop = 769;
  const breakpointIsMobile = width < breakpointSmallLaptop;

  return (
    <SectionWrapper>
      <HeaderTitle>{header_title}</HeaderTitle>
      <HeaderSutitleWrapper>
        <HeaderSubtitle>{header_subtitle}</HeaderSubtitle>
      </HeaderSutitleWrapper>
      <ButtonStyles
        onClick={() => {
          window.open('https://www.wix.com/app-market/hyperhuman', '_blank');
        }}
        label={'GO TO WIX APP'}
        fontSize={breakpointIsMobile ? '14px' : '16px'}
        width={breakpointIsMobile ? 169 : 177}
        height={breakpointIsMobile ? 52 : 60}
      />
    </SectionWrapper>
  );
};

export default HeaderSection;

const ButtonStyles = styled(Button)`
  margin: 34px 0px 0px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 48px auto 0px auto;
  }
`;
const SectionWrapper = styled(SectionWrapperStyles)`
  padding: 0 24px;
  margin-bottom: 80px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 100px;
  }
`;

const HeaderSutitleWrapper = styled.div`
  max-width: 557px;
  margin: 0 auto;
`;
