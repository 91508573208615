import React from 'react';
import styled from '@emotion/styled';
import { SectionTitle, SectionSubtitle, SectionWrapper } from '@styles';
import FAQ, { FAQProps } from '@components/FAQ';
import { WixFAQSectionData } from './types';

const FAQSection: React.FC<WixFAQSectionData> = (props) => {
  const { faq_title, faq_subtitle, faq_items } = props;
  const items: FAQProps[] = faq_items.map((item) => {
    return {
      question: item.faq_question,
      answer: item.faq_answer.html,
    };
  });

  return (
    <SectionWrapper>
      <SectionTitle>{faq_title}</SectionTitle>
      <SectionSubtitle>{faq_subtitle}</SectionSubtitle>
      <FAQWrapper>
        <FAQ items={items} />
      </FAQWrapper>
    </SectionWrapper>
  );
};

export default FAQSection;

const FAQWrapper = styled.div`
  max-width: 700px;
  margin: 0 auto;
`;
