import React from 'react';
import styled from '@emotion/styled';
import { graphql, PageProps } from 'gatsby';
import SEO from '@components/SEO';
import { theme } from '../site/styles';
import FooterSection from '@pageSections/common/footer';
import Separator from '@components/Separator';
import { IPrismicWix, PageContext } from '@appTypes';

import HeaderSection from '@pageSections/wixPage/header';
import HowToPublishSection from '@pageSections/wixPage/howToPublish';
import FAQSection from '@pageSections/wixPage/faq';
import WixExampleSection from '@pageSections/wixPage/wixExample';
import { Helmet } from 'react-helmet';
import CallToActionSection from '@pageSections/common/callToAction';

const { breakpoints } = theme;

const WixPage: React.FC<PageProps<IPrismicWix, PageContext>> = ({
  data: { prismicData, prismicFooterData, prismicCtaData },
  pageContext,
  location,
}) => {
  const { locale } = pageContext;
  const data = prismicData!.edges[0].node.data;

  const { meta_title, meta_description, meta_image, meta_url, faq_items } = data;

  const faqSchemaOrgJSONLD = {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'mainEntity': faq_items.map((item) => {
      return {
        '@type': 'Question',
        'name': item.faq_question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': item.faq_answer.html,
        },
      };
    }),
  };

  return (
    <>
      <SEO
        title={meta_title}
        description={meta_description}
        image={meta_image.url}
        url={meta_url}
        pathname={location.pathname}
        name='Hyperhuman'
        locale={locale}
      />
      <Helmet>
        <script type='application/ld+json'>{JSON.stringify(faqSchemaOrgJSONLD)}</script>
      </Helmet>
      <Container style={{ marginTop: 34 }}>
        <HeaderSection {...data} />
        <Separator />
        <HowToPublishSection {...data} />
        <Separator />
        <WixExampleSection {...data} />
        <Separator />
        <FAQSection {...data} />
      </Container>
      <CallToActionSection prismicCtaData={prismicCtaData} />
      <FooterSection prismicFooterData={prismicFooterData} subscribeForm />
    </>
  );
};

export default WixPage;

const Container = styled.div`
  margin-top: 34px;
  margin-bottom: 70px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 110px;
  }
`;

export const pageQuery = graphql`
  query WixPageQuery($locale: String!) {
    prismicData: allPrismicWix(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          lang
          data {
            meta_title
            meta_keywords
            meta_description
            meta_image {
              url
            }
            meta_url
            header_title
            header_subtitle
            how_to_publish_title
            how_to_publish_subtitle
            how_to_publish_steps {
              step_label
              step_title
              step_subtitle
              type
            }

            example_wix_title
            example_wix_subtitle
            example_wix_url
            example_wix_screenshot {
              url
              alt
            }

            # FAQ
            faq_title
            faq_subtitle
            faq_items {
              faq_question
              faq_answer {
                html
              }
            }
          }
        }
      }
    }
    prismicFooterData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...FooterFieldsFragment
    }
    prismicCtaData: allPrismicIndex(filter: { lang: { eq: $locale } }) {
      ...CtaFieldsFragment
    }
  }
`;
