import React from 'react';
import styled from '@emotion/styled';
import { theme, SectionWrapper as SectionWrapperStyles, SectionTitle, SectionSubtitle } from '@styles';
import { WixExampleSectionData } from './types';
import Button from '@components/Button';
import { useWindowSize } from 'react-use';
import Box from '@components/Box';

const { breakpoints } = theme;

const WixExampleSection: React.FC<WixExampleSectionData> = (props) => {
  const { example_wix_title, example_wix_subtitle, example_wix_screenshot, example_wix_url } = props;

  const { width } = useWindowSize();
  const breakpointSmallLaptop = 769;
  const breakpointIsMobile = width < breakpointSmallLaptop;

  return (
    <SectionWrapper>
      <SectionTitle>{example_wix_title}</SectionTitle>
      <SectionSubtitle>{example_wix_subtitle}</SectionSubtitle>
      <Box padding={'0 32px'}>
        <ButtonStyles
          onClick={() => {
            window.open(example_wix_url, '_blank');
          }}
          label={'GO TO WEBSITE'}
          fontSize={breakpointIsMobile ? '14px' : '16px'}
          width={breakpointIsMobile ? 170 : 187}
          height={breakpointIsMobile ? 52 : 60}
        />
        <WixExampleScreenshotWrapper>
          <img src={example_wix_screenshot.url} alt={example_wix_screenshot.alt} />
        </WixExampleScreenshotWrapper>
      </Box>
    </SectionWrapper>
  );
};

export default WixExampleSection;

const WixExampleScreenshotWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 48px;
  img {
    width: 660px;
  }
`;

const ButtonStyles = styled(Button)`
  margin: 34px 0px 0px 0px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin: 48px auto 0px auto;
  }
`;

const SectionWrapper = styled(SectionWrapperStyles)`
  margin-bottom: 80px;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    margin-bottom: 100px;
  }
`;
