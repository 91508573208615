import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import StepsTimeline from '@components/StepsTimeline';
import { theme } from '@styles';
import Box from './Box';
import { WixHowToPublishStep } from '@pageSections/wixPage/howToPublish/types';
import { useWindowSize } from 'react-use';

const { breakpoints } = theme;

type Props = {
  steps: WixHowToPublishStep[];
  placeholdersMapper: string[];
  appName?: string;
};

const StepsCarouselWix: React.FC<Props> = ({ steps, placeholdersMapper }) => {
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);
  const carouselLoopRef = useRef<ReturnType<typeof setTimeout>>(setTimeout(() => '', 0));
  const { width } = useWindowSize();

  useEffect(() => {
    carouselLoopRef.current = setTimeout(() => {
      if (currentStep < placeholdersMapper.length - 1) {
        setCurrentStep(currentStep + 1);
        setCurrentVideoId(currentStep + 1);
      } else {
        setCurrentStep(0);
        setCurrentVideoId(0);
      }
    }, 3000);
  }, [currentStep]);

  const handleOnStepChange = (step: number) => {
    setCurrentStep(step);
    setCurrentVideoId(step);
    clearTimeout(carouselLoopRef.current);
  };

  const isDesktop = width > 769;

  return (
    <Container>
      {placeholdersMapper.map(
        (_, index) =>
          steps[index] && (
            <Box
              paddingRight={isDesktop ? '40px' : '0px'}
              key={index}
              width={isDesktop ? 452 : 'auto'}
              height={isDesktop ? 643 : 365}
              justifyContent={'center'}
              display={currentVideoId === index ? 'flex' : 'none'}
              flexDirection={'column'}
              marginBottom={isDesktop ? '0px' : '48px'}
            >
              <PlaceholderImage
                alt={steps[index].step_title ? steps[index].step_title : steps[index].step_subtitle}
                key={index}
                showImage={currentVideoId === index}
                src={placeholdersMapper[index]}
                width={'100%'}
                height={'100%'}
              />
            </Box>
          ),
      )}
      <StepsTimeline
        currentStep={currentStep}
        onStepChange={(step: number) => handleOnStepChange(step)}
        stepsArray={steps}
      />
    </Container>
  );
};

export default StepsCarouselWix;
const PlaceholderImage = styled.img<{ showImage: boolean }>`
  object-fit: contain;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    object-position: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.smallLaptop}) {
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`;
